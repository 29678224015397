import { useMemo } from 'react';

import { providers } from 'ethers';
import type { Account, Chain, Client, Transport } from 'viem';
import { Config, useConnectorClient } from 'wagmi';
import { JsonRpcPayload, JsonRpcResponse } from 'web3-core-helpers';

export function clientToProvider (client: Client<Transport, Chain>) {
  const { chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  if (transport.type === 'fallback') {
    return new providers.FallbackProvider(
      (transport.transports as ReturnType<Transport>[]).map(
        ({ value }) => new providers.JsonRpcProvider(value?.url, network),
      ),
    );
  }
  const rpcUrl = transport.url || chain.rpcUrls.default.http[0];
  return new providers.JsonRpcProvider(rpcUrl, network);
}

export function clientToSigner (client: Client<Transport, Chain, Account>) {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

export function gsnRawProvider (jsonRpcSigner: providers.JsonRpcSigner) {
  return {
    ...jsonRpcSigner.provider,
    send: (payload: JsonRpcPayload, callback: (error: Error | null, result?: JsonRpcResponse) => void) => {
      jsonRpcSigner.provider.send(payload.method, payload.params)
        .then((result) => {
          callback(null, { jsonrpc: '2.0', id: Number(payload.id), result });
        })
        .catch((error) => {
          callback(error, undefined);
        });
    },
  };
}

/** Hook to convert a Viem Client to an ethers.js Signer. */
export function useEthersSigner ({ chainId }: { chainId?: number } = {}) {
  const { data: client } = useConnectorClient<Config>({ chainId });
  return useMemo(() => (client ? clientToSigner(client) : undefined), [client]);
}

export function getSignerOrProvider<
  A extends Account | undefined = Account | undefined,
> (
  client: Client<Transport, Chain, A>,
): A extends Account ? providers.JsonRpcSigner : providers.JsonRpcProvider {
  if (client.account) {
    return clientToSigner(
      client as Client<Transport, Chain, Account>,
    ) as A extends Account ? providers.JsonRpcSigner : providers.JsonRpcProvider;
  }

  return clientToProvider(client) as A extends Account
    ? providers.JsonRpcSigner
    : providers.JsonRpcProvider;
}
