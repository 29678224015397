import { QRC20__factory as Erc20 } from '@q-dev/gdk-sdk/lib/ethers-contracts/factories/QRC20__factory';
import { QRC721__factory as Erc721 } from '@q-dev/gdk-sdk/lib/ethers-contracts/factories/QRC721__factory';
import { createContract } from 'context/Web3Provider/helpers/contracts';
import { providers } from 'ethers';

import { ERC_721_INTERFACE_UPGRADEABLE_ID } from 'constants/boundaries';

export const createErc20Contract = (
  address: string,
  provider: providers.JsonRpcProvider | providers.JsonRpcSigner
) => {
  const { contractInstance, contractInterface } = createContract(address, provider, Erc20);

  return {
    contractInstance,
    contractInterface,

    loadDetails: async () => {
      const [
        decimals,
        name,
        owner,
        symbol,
        totalSupply,
        totalSupplyCap
      ] =
        await Promise.all([
          contractInstance.decimals(),
          contractInstance.name(),
          contractInstance.owner(),
          contractInstance.symbol(),
          contractInstance.totalSupply(),
          contractInstance.totalSupplyCap()
        ]);
      return {
        decimals: decimals || 0,
        name: name || '',
        owner: owner || '',
        symbol: symbol || '',
        totalSupply: totalSupply.toString() || '',
        totalSupplyCap: totalSupplyCap.toString() || '',
      };
    },
  };
};

export const createErc721Contract = (
  address: string,
  rawProvider: providers.JsonRpcProvider | providers.JsonRpcSigner
) => {
  const { contractInstance, contractInterface } = createContract(address, rawProvider, Erc721);

  return {
    contractInstance,
    contractInterface,

    loadDetails: async () => {
      const [
        name,
        owner,
        symbol,
        totalSupply,
        totalSupplyCap,
        baseUri
      ] =
        await Promise.all([
          contractInstance.name(),
          contractInstance.owner(),
          contractInstance.symbol(),
          contractInstance.totalSupply(),
          contractInstance.totalSupplyCap(),
          contractInstance.baseURI()
        ]);

      return {
        decimals: 0,
        name,
        owner,
        symbol,
        totalSupply: totalSupply.toString(),
        totalSupplyCap: totalSupplyCap.toString(),
        baseURI: baseUri,
      };
    },

    isUpgradable: async () => {
      return await contractInstance.supportsInterface(ERC_721_INTERFACE_UPGRADEABLE_ID);
    },

    safeCheckIsUpgradable: async () => {
      try {
        return await contractInstance.supportsInterface(ERC_721_INTERFACE_UPGRADEABLE_ID);
      } catch (error) {
        return false;
      }
    }
  };
};
